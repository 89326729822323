import React from "react";
import { kebabCase } from "lodash";
import Helmet from "react-helmet";
import { Link, graphql } from "gatsby";
import Layout from "../../components/Layout";

const TagsPage = ({
  data: {
    allMarkdownRemark: { group },
    site: {
      siteMetadata: { title }
    }
  }
}) => (
  <Layout>
    <section className="l-section-lv2 l-main-media-hero">
      <div className="l-main-media-hero__inner">
        <Helmet title={`Tags | ${title}`} />
        <div className="container content">
          <div className="columns">
            <div
              className="column is-10 is-offset-1"
              style={{ marginBottom: "6rem" }}
            >
              <h1 className="title is-size-2 is-bold-light u-fw-b u-pc-fz20 u-pc-fz17 u-pc-mb30 u-sp-mb20">
                タグ一覧
              </h1>
              <div className="c-news-media__label-tile">
                {group.map(tag => (
                  <Link
                    className="c-news-media__label-tile__item"
                    to={`/tags/${kebabCase(tag.fieldValue)}/`}
                    key={tag.fieldValue}
                  >
                    <div className="c-rounded-label">
                      <p className="c-rounded-label__text">
                        #{tag.fieldValue} ({tag.totalCount})
                      </p>
                    </div>
                  </Link>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </Layout>
);

export default TagsPage;

export const tagPageQuery = graphql`
  query TagsQuery {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(limit: 1000) {
      group(field: frontmatter___tags) {
        fieldValue
        totalCount
      }
    }
  }
`;
